import React, { FC } from "react";
import cx from "classnames";

import { Toast } from "components/ui/Toast";
import { Header } from "components/common/Header";
import { Container } from "components/common/Container";
import { Footer } from "components/common/Footer";
import { CookieConsent } from "components/common/CookieConsent";

import s from "./BaseLayout.module.sass";
import { Heading } from "components/common/Heading";

type BaseLayoutProps = {
  headerClassName?: string;
  className?: string;
};

const alertTitle = "Please migrate to this version";
const alertDescriptionFirstPart =
  "If you have funds in the Yupana V1, please, check out ";
const alertDescriptionSecondPart = " to migrate to the current version";

const YupanaLink = "https://old.yupana.finance/";

export const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  headerClassName,
  className,
}) => (
  <>
    <Header className={headerClassName} />
    <Container main className={cx(s.container, className)}>
      <a href={YupanaLink} className={s.alert}>
        <Heading className={s.alertTitle} title={alertTitle} />
        <p>
          <span>{alertDescriptionFirstPart}</span>
          <span className={s.link}>
            {YupanaLink.replace("https://", "").replace("/", "")}
          </span>
          <span>{alertDescriptionSecondPart}</span>
        </p>
      </a>
      {children}
      <Toast />
    </Container>
    <Footer />
    <CookieConsent />
  </>
);

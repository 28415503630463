var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class MatrixUserService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    /**
     * Log in to the matrix node with username and password
     *
     * @param user
     * @param password
     * @param deviceId
     */
    login(user, password, deviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.post('/login', {
                type: 'm.login.password',
                identifier: {
                    type: 'm.id.user',
                    user
                },
                password,
                device_id: deviceId
            });
        });
    }
}
